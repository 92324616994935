<template>
    <div class="mn_news">
        <NavBar />
        <div class="container mb-3">
            <div class="row justify-content-center">
                <h1>Workshop Forum Discussion Group (FGD) Pengenalan dan Penggunaan Aplikasi Aksara Ulu
                    Oleh Intelligence System Research Group (ISRG) Universitas Bina Darma</h1>
            </div>
        </div>
        <div class="container">
            <div class="row d-flex justify-content-between align-items-center w-100">
                <div class="col-auto">
                    <p class="tag mb-0">Diposting Selasa, 14 November 2023</p>
                </div>
                <div class="col-auto">
                    <p class="tag mb-0">Oleh Tim ISRG</p>
                </div>
            </div>
            <hr>
        </div>
        <div class="container my-0">
            <div class="row">
                <div class="jmb_img col-md-7 my-3">
                    <img src="@/assets/photoworkshop02.jpg" alt="logo" class="lg_img">
                </div>
                <div class="jmb_text col-md-5 my-3">
                    <p class="nt-4">
                        Acara Workshop FGD Pengenalan dan Penggunaan Aplikasi Aksara Ulu oleh ISRG Universitas Bina
                        Darma berlangsung di Ruang Smart Class Lantai 6 Universitas Bina Darma pada hari Selasa, 14
                        November 2023. Acara ini bertujuan untuk memperkenalkan budaya aksara ulu sekaligus melakukan
                        demonstrasi 3 aplikasi aksara ulu hasil penelitian yang dikembangkan oleh tim ISRG kepada
                        peserta. Peserta-peserta yang diundang berasal dari Dinas Kearsipan Provinsi Sumatera Selatan,
                        Dinas Kebudayaan dan Pariwisata Provinsi Sumatera Selatan, Dinas Perpustakaan Provinsi Sumatera
                        Selatan, Pustakawan dari Perpustakaan Daerah di Palembang, Perwakilan dari Komunitas Pecinta
                        Aksara Ulu, Warek RIT Universitas Bina Darma, Warek Akademik Universitas Bina Darma, Manajer
                        Haki UBD, Koordinat Riset UBD dan peserta-peserta lainnya.
                    </p>
                </div>
            </div>
        </div>
        <div class="container my-0">
            <div class="row justify-content-center">
                <div class="jmb_text">
                    <p class="nt-4">
                        Acara ini berlangsung mulai dari pukul 09.00 WIB - 12.00 WIB. Rangkaian kegiatan yang dilakukan
                        dalam acara ini terdapat beberapa hal yaitu mulai dari persiapan, kata sambutan, pembukaan,
                        acara inti dan penutup. Pada sesi materi dan uji coba demonstrasi aplikasi, terdapat 3 sesi yang
                        dilakukan. Sesi pertama, penyampaian materi dan demonstrasi uji coba aplikasi Aksara Ulu
                        berbasis Android yang dipaparkan oleh Ahmad Subhan, S.Kom. Sesi Kedua, penyampaian materi dan
                        demonstrasi aplikasi Transliterasi Aksara Ulu berbasis Android yang disampaikan oleh Irfan
                        Henuarianto, S.Kom. Sesi ketiga, materi dan demonstrasi uji coba Aplikasi SmartEngine Aksara Ulu
                        berbasis Android.
                    </p>
                </div>
                <div class="col-md-6 my-3">
                    <div class="image_grid">
                        <img src="@/assets/photoworkshop05.jpg" class="nws_img01">
                    </div>
                </div>
                <div class="col-md-6 my-3">
                    <div class="image_grid">
                        <img src="@/assets/photoworkshop04.jpg " class="nws_img02">
                    </div>
                </div>
                <div class="col-md-4 my-3">
                    <div class="image_grid">
                        <img src="@/assets/berandaawal.jpg" class="nws_img01">
                    </div>
                </div>
                <div class="col-md-4 my-3">
                    <div class="image_grid">
                        <img src="@/assets/transliterasiaksaramobile.png" class="nws_img01">
                    </div>
                </div>
                <div class="col-md-4 my-3">
                    <div class="image_grid">
                        <img src="@/assets/aplikasialihaksaramobile.png" class="nws_img01">
                    </div>
                </div>
            </div>
        </div>
        <div class="container my-0">
            <div class="row justify-content-center">
                <div class="jmb_text">
                    <p class="nt-4">
                        Pada sesi akhir acara, dilakukan penutupan yang dipimpin langsung oleh ketua riset ISRG yaitu
                        Dr. Yesi Novaria Kunang, S.T., M.Kom. Sebelum bubarnya acara, diadakan sesi photo bersama dengan
                        seluruh peserta acara workshop.
                    </p>
                </div>
                <div class="col-md-6 my-3">
                    <div class="image_grid">
                        <img src="@/assets/photoworkshop01.jpg" class="nws_img02">
                    </div>
                </div>
                <div class="col-md-6 my-3">
                    <div class="image_grid">
                        <img src="@/assets/photoworkshop03.jpg" class="nws_img02">
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <hr>
            <div class="row d-flex justify-content-between align-items-center w-100">
                <div class="col-auto">
                    <router-link class="btn btn_format mb-0" :to="{ name: 'NeWs03' }">Previous</router-link>
                </div>
                <div class="col-auto">
                    <router-link class="btn btn_format mb-0" :to="{ name: 'NeWs05' }">Next</router-link>
                </div>
            </div>
        </div>
        <FooTer />
    </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import FooTer from '@/components/FooTer.vue'
export default {
    name: 'NeWs04',
    components: {
        NavBar,
        FooTer,
    },
}
</script>

<style scoped>
.mn_news {
    margin-top: 140px;
}

.lg_img {
    width: 100%;
    aspect-ratio: 5.5/3;
}

.tag {
    font-size: 12px;
}

.jmb_text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.nt-4 {
    font-size: 14px;
    text-align: justify;
}

.nws_img01 {
    width: 60%;
    height: auto;
}

.nws_img02 {
    width: 100%;
    height: auto;
}

.btn_format {
    background-color: #ffd5b8;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
    transition: .4s all;
}

.btn_format:hover {
    transform: scale(1.2);
    color: #26517c;
}

@media (min-width: 768px) and (max-width: 992px) {
    .nt-4 {
        font-size: 9px;
    }
}
</style>
