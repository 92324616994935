<template>
  <div class="crd_main container">
    <div class="row justify-content-center" id="card-container">
      <div class="col-md-4 my-3">
        <div class="card h-100 d-flex flex-column">
          <img src="@/assets/coverbrt2211.png" class=" crd_img card-img-top" alt="..." />
          <div class="card-body d-flex flex-column">
            <h5 class="card-title">Diskusi dengan penggiat aksara Ulu Prabumulih/Rambang, ibu Yasimas, S.Pd. 22 November
              2024 bertempat di SDN 30 Prabumulih
            </h5>
            <p class="card-text mt-auto">Prabumulih. - Jumat, 22 November 2024 Telah Terjadi...</p>
            <div class="mt-auto">
              <router-link class="btn btn_format" :to="{ name: 'NeWs14' }">Details</router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 my-3">
        <div class="card h-100 d-flex flex-column">
          <img src="@/assets/coverbrt1411.png" class=" crd_img card-img-top" alt="..." />
          <div class="card-body d-flex flex-column">
            <h5 class="card-title">Forum Kelompok Diskusi dan Pengenalan Aplikasi Cerdas Transliterasi Multi Varian
              Aksara Ulu Sumatera Selatan
            </h5>
            <p class="card-text mt-auto">Palembang. - Kamis, 14 November 2024 Telah Terjadi...</p>
            <div class="mt-auto">
              <router-link class="btn btn_format" :to="{ name: 'NeWs13' }">Details</router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 my-3">
        <div class="card h-100 d-flex flex-column">
          <img src="@/assets/coverbrt1710.jpg" class=" crd_img card-img-top" alt="..." />
          <div class="card-body d-flex flex-column">
            <h5 class="card-title">Diskusi Kelanjutan Pengembangan Aplikasi Aksara Ulu Sumatera Selatan bersama Dinas
              Kebudayaan dan Pariwisata
            </h5>
            <p class="card-text mt-auto">Palembang. - Kamis, 17 Oktober 2024 Telah Terjadi...</p>
            <div class="mt-auto">
              <router-link class="btn btn_format" :to="{ name: 'NeWs12' }">Details</router-link>
            </div>
          </div>
        </div>
      </div>
      <!-- Additional cards initially hidden -->
      <div class="col-md-4 my-3 d-none">
        <div class="card h-100 d-flex flex-column">
          <img src="@/assets/coverbrt1809part2.jpg" class=" crd_img card-img-top" alt="..." />
          <div class="card-body d-flex flex-column">
            <h5 class="card-title">Kunjungan Dinas Kebudayaan dan
              Pariwisata Provinsi Sumatera Selatan ke ISRG Universitas Bina Darma
            </h5>
            <p class="card-text mt-auto">Palembang. - Rabu, 18 September 2024 Telah Terjadi...</p>
            <div class="mt-auto">
              <router-link class="btn btn_format" :to="{ name: 'NeWs11' }">Details</router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 my-3 d-none">
        <div class="card h-100 d-flex flex-column">
          <img src="@/assets/coverbrt1809.jpg" class=" crd_img card-img-top" alt="..." />
          <div class="card-body d-flex flex-column">
            <h5 class="card-title">Audiensi Ketua ISRG Universitas Bina Darma
              dengan PLT. Kepala BBPSS di UPT Balai Bahasa
              Provinsi Sumatera Selatan
            </h5>
            <p class="card-text mt-auto">Palembang. - Rabu, 18 September 2024 Telah Terjadi...</p>
            <div class="mt-auto">
              <router-link class="btn btn_format" :to="{ name: 'NeWs10' }">Details</router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 my-3 d-none">
        <div class="card h-100 d-flex flex-column">
          <img src="@/assets/kunjunganlinggau.jpg" class=" crd_img card-img-top" alt="..." />
          <div class="card-body d-flex flex-column">
            <h5 class="card-title">Kunjungan ke Museum Subkoss Lubuk Linggau untuk Diskusi tentang Aksara Ulu Varian
              Lubuk Linggau</h5>
            <p class="card-text mt-auto">Lubuk Linggau. - Rabu, 11 September 2024 Telah Terjadi...</p>
            <div class="mt-auto">
              <router-link class="btn btn_format" :to="{ name: 'NeWs09' }">Details</router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 my-3 d-none">
        <div class="card h-100 d-flex flex-column">
          <img src="@/assets/coverbrt0909.jpg" class=" crd_img card-img-top" alt="..." />
          <div class="card-body d-flex flex-column">
            <h5 class="card-title">Diskusi Bersama Ariq Syauqi, MA. dan Komunitas Aksara Ulu Sumsel Mengenai Penelitian
              Aksara di Selatan Sumatera</h5>
            <p class="card-text mt-auto">Palembang. - Senin, 9 September 2024 Telah Terjadi...</p>
            <div class="mt-auto">
              <router-link class="btn btn_format" :to="{ name: 'NeWs08' }">Details</router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 my-3 d-none">
        <div class="card h-100 d-flex flex-column">
          <img src="@/assets/coverbrt2608part2.jpg" class=" crd_img card-img-top" alt="..." />
          <div class="card-body d-flex flex-column">
            <h5 class="card-title">Diskusi Pengembangan Multivarian Aksara Ulu Sumatera Selatan Bersama Dinas Kebudayaan
              dan Pariwisata Sumatera Selatan
            </h5>
            <p class="card-text mt-auto">Palembang. - Senin, 26 Agustus 2024 Telah Terjadi...</p>
            <div class="">
              <router-link class="btn btn_format" :to="{ name: 'NeWs07' }">Details</router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 my-3 d-none">
        <div class="card h-100 d-flex flex-column">
          <img src="@/assets/coverbrt2608.jpg" class=" crd_img card-img-top" alt="..." />
          <div class="card-body d-flex flex-column">
            <h5 class="card-title">Kunjungan dan Diskusi Riset ISRG dengan Dinas Perpustakaan Daerah Sumatera Selatan
            </h5>
            <p class="card-text mt-auto">Palembang. - Senin, 26 Agustus 2024 Telah Terjadi...</p>
            <div class="">
              <router-link class="btn btn_format" :to="{ name: 'NeWs06' }">Details</router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 my-3 d-none">
        <div class="card h-100 d-flex flex-column">
          <img src="@/assets/cover02.jpg" class=" crd_img card-img-top" alt="..." />
          <div class="card-body d-flex flex-column">
            <h5 class="card-title">FGD Kearifan Lokal "Pelestarian Naskah Kuno Aksara Ulu Sumatera Selatan" dan
              Launching Aplikasi AKAS</h5>
            <p class="card-text mt-auto">Palembang. - Rabu, 03 Juli 2024 Telah Terjadi...</p>
            <div class="mt-auto">
              <router-link class="btn btn_format" :to="{ name: 'NeWs05' }">Details</router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 my-3 d-none">
        <div class="card h-100 d-flex flex-column">
          <img src="@/assets/cover01.jpg" class="crd_img card-img-top" alt="..." />
          <div class="card-body d-flex flex-column">
            <h5 class="card-title">Workshop Forum Discussion Group (FGD) Pengenalan dan Penggunaan Aplikasi Aksara Ulu
              Oleh Intelligence System Research Group (ISRG) Universitas Bina Darma</h5>
            <p class="card-text mt-auto">Palembang. - Selasa, 14 November 2023 Telah Terjadi...</p>
            <div class="mt-auto">
              <router-link class="btn btn_format" :to="{ name: 'NeWs04' }">Details</router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 my-3 d-none">
        <div class="card h-100 d-flex flex-column">
          <img src="@/assets/transliterasiaksara.png" class="crd_img card-img-top" alt="..." />
          <div class="card-body d-flex flex-column">
            <h5 class="card-title">Aplikasi Transliterasi Aksara Latin ke Aksara Ulu Berbasis Website</h5>
            <p class="card-text mt-auto">Palembang. - Kamis, 21 September 2023 Telah terjadi...</p>
            <div class="">
              <router-link class="btn btn_format" :to="{ name: 'NeWs03' }">Details</router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 my-3 d-none">
        <div class="card h-100 d-flex flex-column">
          <img src="@/assets/alihaksara.png" class="crd_img card-img-top" alt="..." />
          <div class="card-body d-flex flex-column">
            <h5 class="card-title">Aplikasi Alih Aksara Dokumen Aksara Ulu Berbasis Website</h5>
            <p class="card-text mt-auto">Palembang. - Sabtu, 16 September 2023 Telah terjadi...</p>
            <div class="mt-auto">
              <router-link class="btn btn_format" :to="{ name: 'NeWs02' }">Details</router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 my-3 d-none">
        <div class="card h-100 d-flex flex-column">
          <img src="@/assets/aksaraulu.png" class="crd_img card-img-top" alt="..." />
          <div class="card-body">
            <h5 class="card-title">Aplikasi Aksara Ulu (Kanganga) di Platform Android</h5>
            <p class="card-text mt-auto">Palembang. - Selasa, 10 Oktober 2022 Telah terjadi...</p>
            <div class="mt-auto">
              <router-link class="btn btn_format" :to="{ name: 'NeWs01' }">Details</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- More button -->
    <div class="row mt-5">
      <div class="col-12 text-center my-5">
        <button id="show-more" class="btn btn_format">More</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardsMenu',
  mounted() {
    const showMoreButton = document.getElementById('show-more');

    showMoreButton.addEventListener('click', function () {
      // Ambil semua kartu yang tersembunyi (dengan class 'd-none')
      const hiddenCards = document.querySelectorAll('.col-md-4.d-none');

      // Tampilkan maksimal 3 kartu per klik
      for (let i = 0; i < 3 && i < hiddenCards.length; i++) {
        hiddenCards[i].classList.remove('d-none'); // Hapus class 'd-none' untuk menampilkan kartu
      }

      // Jika tidak ada kartu tersembunyi yang tersisa, sembunyikan tombol "More"
      if (document.querySelectorAll('.col-md-4.d-none').length === 0) {
        showMoreButton.style.display = 'none';
      }
    });
  }
}
</script>


<style scoped>
.card {
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
  border: none;
}

.card-title {
  font-size: 18px;
  font-weight: bold;
}

.card-text {
  font-size: 14px;
}

.crd_img {
  aspect-ratio: 3/2;
}

.btn_format {
  background-color: #ffd5b8;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
  transition: .4s all;
}

.btn_format:hover {
  transform: scale(1.2);
  color: #26517c;
}
</style>
