<template>
  <div class="container-fluid px-0">
    <footer class="py-5">
      <div class="row justify-content-center mx-0 px-2">
        <div class="col-6 col-md-2 mb-3 ms-3 me-3">
          <h5>ABOUT UBD</h5>
          <ul class="nav flex-column">
            <li class="nav-item mb-3"><a href="https://www.binadarma.ac.id/fasilitas/"
                class="nav-link p-0">Facilities</a></li>
            <li class="nav-item mb-3"><a href="https://www.binadarma.ac.id/sejarah/" class="nav-link p-0">History</a>
            </li>
            <li class="nav-item mb-3"><a href="https://www.binadarma.ac.id/visi-dan-misi/" class="nav-link p-0">Vision
                and Mission</a></li>
            <li class="nav-item mb-3"><a href="https://www.binadarma.ac.id/identitas-ubd" class="nav-link p-0">UBD
                Identity</a></li>
            <li class="nav-item mb-3"><a href="https://www.binadarma.ac.id/struktur-organisasi-ubd/"
                class="nav-link p-0">UBD Structure</a></li>
            <li class="nav-item mb-3"><a href="https://perpustakaan.binadarma.ac.id/" class="nav-link p-0">UBD
                Library</a></li>
          </ul>
        </div>
        <div class="col-6 col-md-2 mb-3 ms-3 me-3">
          <h5>EDUCATION</h5>
          <ul class="nav flex-column">
            <li class="nav-item mb-3"><a href="https://www.binadarma.ac.id/akademik/" class="nav-link p-0">Academic</a>
            </li>
            <li class="nav-item mb-3"><a href="https://www.binadarma.ac.id/beasiswa/"
                class="nav-link p-0">Scholarship</a></li>
          </ul>
        </div>
        <div class="col-6 col-md-2 mb-3 ms-3 me-3">
          <h5>ACHIEVEMENTS</h5>
          <ul class="nav flex-column">
            <li class="nav-item mb-3"><a href="https://www.binadarma.ac.id/prestasi-kampus/" class="nav-link p-0">Campus
                Achievements</a></li>
            <li class="nav-item mb-3"><a href="https://www.binadarma.ac.id/prestasi-mahasiswa-2/"
                class="nav-link p-0">Student Achievements</a></li>
          </ul>
        </div>
        <div class="col-6 col-md-2 mb-3 ms-3 me-3">
          <h5>PARTNERSHIPS</h5>
          <ul class="nav flex-column">
            <li class="nav-item mb-3"><a href="https://www.binadarma.ac.id/kerjasama/"
                class="nav-link p-0">Collaboration</a></li>
          </ul>
        </div>
      </div>
      <div class="sosmed d-flex flex-column flex-sm-row justify-content-center py-4 my-4">
        <ul class="list-unstyled d-flex justify-content-center flex-wrap">
          <li class="ms-3 mx-4"><a class="link-dark" href="https://wa.me/089643989565"><i
                class="bi bi-whatsapp"></i></a></li>
          <li class="ms-3 mx-4"><a class="link-dark" href="https://www.instagram.com/isrg_ubd/"><i
                class="bi bi-instagram"></i></a></li>
          <li class="ms-3 mx-4"><a class="link-dark" href="https://www.facebook.com/isrg.ubd/"><i
                class="bi bi-facebook"></i></a></li>
          <li class="ms-3 mx-4"><a class="link-dark" href="https://www.youtube.com/@IntelligentSystemResearchGroup"><i
                class="bi bi-youtube"></i></a></li>
          <li class="ms-3 mx-4"><a class="link-dark" href="mailto:isrg2@binadarma.ac.id"><i
                class="bi bi-envelope"></i></a></li>
        </ul>
      </div>
      <p>© 2024 Intelligent Systems Research Group</p>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'FooTer',
}
</script>

<style scoped>
.container-fluid {
  margin-top: 150px;
}

.row {
  margin-top: 40px;
}

footer {
  background-color: #26517c;
  width: 100%;
  color: #fff;
}

h5 {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 40px;
}

.nav-link {
  color: #fff;
  position: relative;
  font-size: 13px;
}

.nav-link:hover,
.nav-link.active {
  color: #ffd5b8;
  transition: 0.3s ease-in-out
}

.nav-link::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 2px;
  background-color: #ffd5b8;
  visibility: hidden;
  transition: 0.3s ease-in-out;
}

.nav-link:hover::before,
.nav-link.active::before {
  width: 80%;
  visibility: visible;
}

.sosmed ul li .bi {
  font-size: 35px;
  color: #000;
  line-height: 60px;
  transition: .3s;
}

.sosmed ul li .bi:hover {
  color: #fff;
}

.sosmed ul li a {
  position: relative;
  display: block;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #fff;
  text-align: center;
  transition: .3s;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
}

.sosmed ul li a:hover {
  transform: translate(0, -10px);
}

.sosmed ul li:nth-child(1) a:hover {
  background-color: #25D366;
}

.sosmed ul li:nth-child(2) a:hover {
  background-color: #E4405F;
}

.sosmed ul li:nth-child(3) a:hover {
  background-color: #1877F2;
}

.sosmed ul li:nth-child(4) a:hover {
  background-color: #ff0000;
}

.sosmed ul li:nth-child(5) a:hover {
  background-color: #23A2E4;
}

@media (max-width: 400px) {
  .sosmed ul li {
    margin-top: 30px;
  }
}
</style>
