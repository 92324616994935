<template>
    <div class="mn_news">
        <NavBar />
        <div class="container mb-3">
            <div class="row justify-content-center">
                <h1>Diskusi Pengembangan Multivarian Aksara Ulu Sumatera Selatan Bersama Dinas Kebudayaan dan Pariwisata
                    Sumatera Selatan
                </h1>
            </div>
        </div>
        <div class="container">
            <div class="row d-flex justify-content-between align-items-center w-100">
                <div class="col-auto">
                    <p class="tag mb-0">Diposting Senin, 26 Agustus 2024</p>
                </div>
                <div class="col-auto">
                    <p class="tag mb-0">Oleh Tim ISRG</p>
                </div>
            </div>
            <hr>
        </div>
        <div class="container my-0">
            <div class="row">
                <div class="jmb_img col-md-7 my-3">
                    <img src="@/assets/coverbrt2608part2.jpg" alt="logo" class="lg_img">
                </div>
                <div class="jmb_text col-md-5 my-3">
                    <p class="nt-4">
                        Kunjungan yang dilakukan oleh Tim ISRG kali ini ke Dinas Kebudayaan dan Pariwisata Sumatera
                        Selatan. Kunjungan yang dilakukan bertujuan untuk silaturahmi sekaligus berdiskusi terkait
                        pengembangan multi varian aksara Ulu Sumatera Selatan. Kedatangan tim ISRG disambut baik oleh
                        mitra Dinas Kebudayaan dan Pariwisata Sumatera Selatan yaitu Kepala bidang dokumentasi dan
                        Publikasi Budaya Hj. Vinita Citra Kiarini, SE., MSi dan staff di ruang rapat Sapta Pesona. Untuk
                        diskusi yang ingin dilakukan, mitra Dinas Kebudayaan dan Pariwisata menyambut tim ISRG untuk
                        melakukan diskusi bersama di gedung ruang rapat Sapta Pesona. Dalam diskusi yang dilakukan,
                        terlibatlah beberapa pihak-pihak penting baik dari tim ISRG sendiri ataupun dari mitra Dinas
                        Kebudayaan dan Pariwisata Sumatera Selatan.
                    </p>
                </div>
            </div>
        </div>
        <div class="container my-0">
            <div class="row justify-content-center">
                <div class="jmb_text">
                    <p class="nt-4">
                        Dalam kegiatan diskusi yang berlangsung, ketua tim ISRG yaitu Dr. Yesi Novaria Kunang, S.T.,
                        M.Kom. dan tim ISRG lainnya saling bertukar pikiran dengan mitra Dinas Kebudayaan dan Pariwisata
                        Sumatera Selatan untuk membahas pengembangan teknologi multi varian aksara Ulu Sumatera Selatan.
                        Teknologi multi varian aksara Ulu merupakan pendekatan inovatif yang mengintegrasikan berbagai
                        bentuk, gaya, dan penggunaan aksara Ulu ke dalam format digital, seperti font komputer, aplikasi
                        pembelajaran interaktif, dan media visual modern. Dengan teknologi ini, aksara Ulu tidak hanya
                        dilestarikan dalam bentuk tradisional, tetapi juga disesuaikan dengan kebutuhan era digital,
                        sehingga dapat diakses oleh generasi muda maupun masyarakat global. Manfaat dari teknologi ini
                        juga memungkinkan aksara Ulu untuk digunakan dalam desain produk budaya, promosi pariwisata,
                        hingga pengembangan aplikasi kreatif berbasis budaya lokal. Dampaknya, aksara Ulu dapat menjadi
                        identitas budaya yang lebih relevan dan menarik di era modern, memperkuat kebanggaan masyarakat
                        Sumatera Selatan terhadap warisan leluhur mereka sekaligus membuka peluang ekonomi kreatif yang
                        berbasis budaya lokal.
                    </p>
                </div>
                <div class="col-md-6 my-3">
                    <div class="image_grid">
                        <img src="@/assets/brt2608part2bg1.jpg" class="nws_img02">
                    </div>
                </div>
                <div class="col-md-6 my-3">
                    <div class="image_grid">
                        <img src="@/assets/brt2608part2bg2.jpg" class="nws_img02">
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <hr>
            <div class="row d-flex justify-content-between align-items-center w-100">
                <div class="col-auto">
                    <router-link class="btn btn_format mb-0" :to="{ name: 'NeWs06' }">Previous</router-link>
                </div>
                <div class="col-auto">
                    <router-link class="btn btn_format mb-0" :to="{ name: 'NeWs08' }">Next</router-link>
                </div>
            </div>
        </div>
        <FooTer />
    </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import FooTer from '@/components/FooTer.vue'
export default {
    name: 'NeWs07',
    components: {
        NavBar,
        FooTer,
    },
}
</script>

<style scoped>
.mn_news {
    margin-top: 140px;
}

.lg_img {
    width: 100%;
    aspect-ratio: 5.5/3;
}

.tag {
    font-size: 12px;
}

.jmb_text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.nt-4 {
    font-size: 13px;
    text-align: justify;
}

.nws_img01 {
    width: 80%;
    height: auto;
}

.nws_img02 {
    width: 100%;
    height: auto;
}

.btn_format {
    background-color: #ffd5b8;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
    transition: .4s all;
}

.btn_format:hover {
    transform: scale(1.2);
    color: #26517c;
}

@media (min-width: 768px) and (max-width: 992px) {
    .nt-4 {
        font-size: 9px;
    }
}
</style>
