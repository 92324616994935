<template>
    <div class="mn_news">
        <NavBar />
        <div class="container mb-3">
            <div class="row justify-content-center">
                <h1>Diskusi Kelanjutan Pengembangan Aplikasi Aksara Ulu Sumatera Selatan bersama Dinas Kebudayaan dan
                    Pariwisata</h1>
            </div>
        </div>
        <div class="container">
            <div class="row d-flex justify-content-between align-items-center w-100">
                <div class="col-auto">
                    <p class="tag mb-0">Diposting Kamis, 17 Oktober 2024</p>
                </div>
                <div class="col-auto">
                    <p class="tag mb-0">Oleh Tim ISRG</p>
                </div>
            </div>
            <hr>
        </div>
        <div class="container my-0">
            <div class="row">
                <div class="jmb_img col-md-7 my-3">
                    <img src="@/assets/coverbrt1710.jpg" alt="logo" class="lg_img">
                </div>
                <div class="jmb_text col-md-5 my-3">
                    <p class="nt-4">
                        Pada 17 Oktober 2024, Intelligent Systems Research Group (ISRG) Universitas Bina Darma kembali
                        melanjutkan diskusi bersama Hj. Vinita Citra Karini, SE., M.Si., Kepala Bidang Dokumentasi dan
                        Publikasi Budaya Dinas Kebudayaan dan Pariwisata Provinsi Sumatera Selatan. Pertemuan yang
                        berlangsung di Universitas Bina Darma ini dihadiri langsung oleh Ketua ISRG, Dr. Yesi Novaria
                        Kunang, S.T., M.Kom., untuk membahas perkembangan terbaru riset Aksara Ulu Sumatera Selatan
                        Multi Variant.
                    </p>
                </div>
            </div>
        </div>
        <div class="container my-0">
            <div class="row justify-content-center">
                <div class="jmb_text">
                    <p class="nt-4">
                        Diskusi kali ini lebih mendalam, menyoroti hasil awal penelitian ISRG terkait variasi aksara Ulu
                        yang ditemukan di berbagai wilayah Sumatera Selatan. Kedua pihak juga mengevaluasi strategi
                        pelestarian yang dapat mengintegrasikan teknologi digital, seperti aplikasi interaktif untuk
                        belajar aksara Ulu dan digitalisasi manuskrip kuno. Melalui pertemuan ini, diharapkan kolaborasi
                        antara ISRG dan Dinas Kebudayaan dapat menciptakan terobosan baru dalam pelestarian dan
                        pengembangan aksara Ulu sebagai identitas budaya yang tetap relevan di era modern.
                    </p>
                </div>
                <div class="col-md-6 my-3">
                    <div class="image_grid">
                        <video class="nws_img02" controls>
                            <source src="@/assets/brt1710bg1.mp4" type="video/mp4">
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>
                <div class="col-md-6 my-3">
                    <div class="image_grid">
                        <img src="@/assets/brt1710bg2.jpg " class="nws_img01">
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <hr>
            <div class="row d-flex justify-content-between align-items-center w-100">
                <div class="col-auto">
                    <router-link class="btn btn_format mb-0" :to="{ name: 'NeWs11' }">Previous</router-link>
                </div>
                <div class="col-auto">
                    <router-link class="btn btn_format mb-0" :to="{ name: 'NeWs01' }">Next</router-link>
                </div>
            </div>
        </div>
        <FooTer />
    </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import FooTer from '@/components/FooTer.vue'
export default {
    name: 'NeWs12',
    components: {
        NavBar,
        FooTer,
    },
}
</script>

<style scoped>
.mn_news {
    margin-top: 140px;
}

.lg_img {
    width: 100%;
    aspect-ratio: 5.5/3;
}

.tag {
    font-size: 12px;
}

.jmb_text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.nt-4 {
    font-size: 13px;
    text-align: justify;
}

.nws_img01 {
    width: 100%;
    height: auto;
}

.nws_img02 {
    width: 100%;
    height: auto;
}

.btn_format {
    background-color: #ffd5b8;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
    transition: .4s all;
}

.btn_format:hover {
    transform: scale(1.2);
    color: #26517c;
}

@media (min-width: 768px) and (max-width: 992px) {
    .nt-4 {
        font-size: 9px;
    }
}
</style>
