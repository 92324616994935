<template>
    <div class="mn_news">
        <NavBar />
        <div class="container mb-3">
            <div class="row justify-content-center">
                <h1>Aplikasi Aksara Ulu (Kaganga) di Platform Android</h1>
            </div>
        </div>
        <div class="container">
            <div class="row d-flex justify-content-between align-items-center w-100">
                <div class="col-auto">
                    <p class="tag mb-0">Diposting Selasa, 10 Oktober 2022</p>
                </div>
                <div class="col-auto">
                    <p class="tag mb-0">Oleh Tim ISRG</p>
                </div>
            </div>
            <hr>
        </div>
        <div class="container my-0">
            <div class="row">
                <div class="jmb_img col-md-7 my-3">
                    <img src="@/assets/aksaraulumobile.png" alt="logo" class="lg_img">
                </div>
                <div class="jmb_text col-md-5 my-3">
                    <p class="nt-4">
                        Aksara Ulu (Kaganga) adalah sebuah aplikasi yang berfungsi untuk mendeteksi dan menemukan
                        kemiripan tulisan yang diambil menggunakan kamera atau ditulis langsung dengan huruf asli dari
                        aksara (Kaganga). Aplikasi ini berguna untuk membantu pengguna dalam mengenali
                        huruf-huruf aksara ulu dari gambar. Selain itu juga, aplikasi ini memiliki banyak fitur-fitur
                        yang dapat menambah wawasan pengguna seputar budaya tulisan kuno Sumatera Selatan. Gambar di
                        samping menunjukkan tampilan aplikasi Aksara Ulu
                        (Kaganga) yang bisa
                        diunduh dari Google Playstore.
                    </p>
                </div>
            </div>
        </div>
        <div class="container my-0">
            <div class="row justify-content-center">
                <div class="jmb_text">
                    <p class="nt-4">
                        Terdapat beberapa fitur yang ada di aplikasi Aksara Ulu (Kaganga) versi mobile ini yaitu
                        seperti informasi sejarah Suku Komering. Di menu ini akan terdapat informasi berupa cerita asal
                        mula Suku Komering yang diceritakan dan dapat dibaca oleh pengguna. Selain itu, terdapat juga
                        informasi tentang naskah-naskah kuno dari Sumatera Selatan seperti naskah Aksara Ulu (Kaganga).
                        Ada juga menu untuk melihat bentuk-bentuk huruf aksara ulu beserta arti latinnya. Disini
                        terdapat cukup banyak huruf aksara ulu yang dapat diketahui dengan beragam bentuk yang
                        berbeda-beda. Menu lainnya yaitu kamus latin ke aksara ulu. Kamus ini dapat menerjemahkan huruf
                        latin menjadi huruf aksara ulu baik dalam bentuk huruf saja, kata ataupun kalimat.
                    </p>
                </div>
                <div class="col-md-2 my-3">
                    <div class="image_grid">
                        <img src="@/assets/berandaawal.jpg " class="nws_img01">
                    </div>
                </div>
                <div class="col-md-2 my-3">
                    <div class="image_grid">
                        <img src="@/assets/sukukomering.jpg " class="nws_img01">
                    </div>
                </div>
                <div class="col-md-2 my-3">
                    <div class="image_grid">
                        <img src="@/assets/naskahulu.jpg " class="nws_img01">
                    </div>
                </div>
                <div class="col-md-2 my-3">
                    <div class="image_grid">
                        <img src="@/assets/hurufaksara.jpg " class="nws_img01">
                    </div>
                </div>
                <div class="col-md-2 my-3">
                    <div class="image_grid">
                        <img src="@/assets/kamuslatinaksara.jpg " class="nws_img01">
                    </div>
                </div>
            </div>
        </div>
        <div class="container my-0">
            <div class="row justify-content-center">
                <div class="jmb_text">
                    <p class="nt-4">
                        Dibalik banyaknya fitur tersebut, terdapat 3 fitur utama dalam aplikasi ini yaitu fitur untuk
                        mengenali karakter aksara ulu melalui photo langsung, fitur untuk mengenali karakter aksara ulu
                        melalui upload photo dan fitur untuk mengenali karakter aksara ulu melalui tulisan tangan. Fitur
                        pertama yaitu pengguna dapat melakukan pengecekan karakter aksara ulu melalui pengambilan photo
                        secara langsung pada dokumen atau gambar karakter aksara ulu untuk dikenali. Fitur kedua yaitu
                        file gambar yang telah diupload akan dilakukan pengecekan huruf aksara ulu yang terdapat di
                        gambar tersebut. Dan fitur ketiga yaitu pengenalan karakter aksara ulu melalui tulisan tangan
                        yang dilakukan oleh pengguna. Untuk fitur pertama dan fitur kedua, setelah pengambilan photo
                        secara langsung ataupun upload file gambar, akan ada menu cropping dan resize photo yang dapat
                        diatur sesuai keperluan pengguna. Setelah pengaturan tersebut, akan dilakukan pengecekan
                        karakter aksara ulu terhadap gambar yang telah diatur.
                    </p>
                </div>
                <div class="col-md-3 my-3">
                    <div class="image_grid">
                        <img src="@/assets/ambilgambar.jpg " class="nws_img02">
                    </div>
                </div>
                <div class="col-md-3 my-3">
                    <div class="image_grid">
                        <img src="@/assets/cropgambar.jpg " class="nws_img02">
                    </div>
                </div>
                <div class="col-md-3 my-3">
                    <div class="image_grid">
                        <img src="@/assets/hasilujicobagambar.jpg " class="nws_img02">
                    </div>
                </div>
                <div class="col-md-3 my-3">
                    <div class="image_grid">
                        <img src="@/assets/hasilujicobatulistangan.jpg " class="nws_img02">
                    </div>
                </div>
            </div>
        </div>
        <div class="container my-0">
            <div class="row justify-content-center">
                <div class="jmb_text">
                    <p class="nt-4">
                        Adapun video tutorial terkait penggunaan aplikasi Aksara Ulu (Kaganga) versi mobile dapat
                        dilihat melalui video tutorial di youtube berikut.
                    </p>
                </div>
                <iframe class="well well-sm col-xs-8" width="560" height="315"
                    src="https://www.youtube.com/embed/ckD206rQ59s?si=KXuhXcdrb4TpI-9P" title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
        </div>
        <div class="container">
            <hr>
            <div class="row d-flex justify-content-between align-items-center w-100">
                <div class="col-auto">
                    <router-link class="btn btn_format mb-0" :to="{ name: 'NeWs14' }">Previous</router-link>
                </div>
                <div class="col-auto">
                    <router-link class="btn btn_format mb-0" :to="{ name: 'NeWs02' }">Next</router-link>
                </div>
            </div>
        </div>
        <FooTer />
    </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import FooTer from '@/components/FooTer.vue'
export default {
    name: 'NeWs01',
    components: {
        NavBar,
        FooTer,
    },
}
</script>

<style scoped>
.mn_news {
    margin-top: 140px;
}

.lg_img {
    width: 100%;
    aspect-ratio: 5.5/3;
}

.tag {
    font-size: 12px;
}

.jmb_text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.nt-4 {
    font-size: 14px;
    text-align: justify;
}

.nws_img01 {
    width: 80%;
    height: auto;
}

.nws_img02 {
    width: 60%;
    height: auto;
}

.btn_format {
    background-color: #ffd5b8;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
    transition: .4s all;
}

.btn_format:hover {
    transform: scale(1.2);
    color: #26517c;
}

@media (min-width: 768px) and (max-width: 992px) {
    .nt-4 {
        font-size: 9px;
    }
}
</style>
