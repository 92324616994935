<template>
    <div class="mn_news">
        <NavBar />
        <div class="container mb-3">
            <div class="row justify-content-center">
                <h1>Forum Kelompok Diskusi dan Pengenalan Aplikasi Cerdas Transliterasi Multi Varian
                    Aksara Ulu Sumatera Selatan</h1>
            </div>
        </div>
        <div class="container">
            <div class="row d-flex justify-content-between align-items-center w-100">
                <div class="col-auto">
                    <p class="tag mb-0">Diposting Kamis, 14 November 2024</p>
                </div>
                <div class="col-auto">
                    <p class="tag mb-0">Oleh Tim ISRG</p>
                </div>
            </div>
            <hr>
        </div>
        <div class="container my-0">
            <div class="row">
                <div class="jmb_img col-md-7 my-3">
                    <img src="@/assets/coverbrt1411.png" alt="logo" class="lg_img">
                </div>
                <div class="jmb_text col-md-5 my-3">
                    <p class="nt-4">
                        Universitas Bina Darma melalui Intelligent Systems Research Group (ISRG) menggelar Forum
                        Kelompok Diskusi sekaligus memperkenalkan Aplikasi Cerdas Transliterasi Multi Varian Aksara Ulu
                        Sumatera Selatan pada sebuah acara yang berlangsung sukses. Forum ini dihadiri oleh berbagai
                        pihak terkait, termasuk Dinas Kebudayaan dan Pariwisata Provinsi Sumatera Selatan, Dinas
                        Perpustakaan Provinsi Sumatera Selatan, Dinas Kearsipan Provinsi Sumatera Selatan, Komunitas
                        Pecinta Aksara Ulu Sumatera Selatan, dan Balai Bahasa Provinsi Sumatera Selatan.
                    </p>
                </div>
            </div>
        </div>
        <div class="container my-0">
            <div class="row justify-content-center">
                <div class="jmb_text">
                    <p class="nt-4">
                        Diskusi ini membahas pentingnya transliterasi aksara Ulu sebagai salah satu langkah pelestarian
                        dan pengembangan budaya Sumatera Selatan di tengah modernisasi. Aplikasi yang diperkenalkan oleh
                        ISRG dirancang untuk mempermudah proses transliterasi berbagai varian aksara Ulu yang selama ini
                        menjadi tantangan bagi pelestarian budaya lokal. Aplikasi ini dilengkapi dengan fitur cerdas
                        berbasis kecerdasan buatan yang mampu mengidentifikasi dan menerjemahkan tulisan dalam aksara
                        Ulu dari berbagai daerah di Sumatera Selatan, sehingga dapat digunakan oleh masyarakat umum
                        maupun akademisi.
                    </p>
                </div>
                <div class="jmb_text">
                    <p class="nt-4">
                        Para peserta forum menyambut baik aplikasi ini dan memberikan berbagai masukan terkait
                        pengembangan lebih lanjut, seperti penambahan fitur edukasi, pengintegrasian aksara Ulu ke dalam
                        perangkat pembelajaran formal, serta pelatihan penggunaan aplikasi kepada masyarakat. Kolaborasi
                        antara ISRG, pemerintah daerah, komunitas budaya, dan institusi terkait ini diharapkan mampu
                        memberikan dampak signifikan dalam memperkenalkan kembali aksara Ulu sebagai bagian dari
                        identitas budaya Sumatera Selatan di era digital.
                    </p>
                </div>
                <div class="col-md-6 my-3">
                    <div class="image_grid">
                        <video class="nws_img02" controls>
                            <source src="@/assets/brt1411bg1.mp4" type="video/mp4">
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <hr>
            <div class="row d-flex justify-content-between align-items-center w-100">
                <div class="col-auto">
                    <router-link class="btn btn_format mb-0" :to="{ name: 'NeWs12' }">Previous</router-link>
                </div>
                <div class="col-auto">
                    <router-link class="btn btn_format mb-0" :to="{ name: 'NeWs14' }">Next</router-link>
                </div>
            </div>
        </div>
        <FooTer />
    </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import FooTer from '@/components/FooTer.vue'
export default {
    name: 'NeWs13',
    components: {
        NavBar,
        FooTer,
    },
}
</script>

<style scoped>
.mn_news {
    margin-top: 140px;
}

.lg_img {
    width: 100%;
    aspect-ratio: 5.5/3;
}

.tag {
    font-size: 12px;
}

.jmb_text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.nt-4 {
    font-size: 13px;
    text-align: justify;
}

.nws_img01 {
    width: 100%;
    height: auto;
}

.nws_img02 {
    width: 65%;
    height: auto;
}

.btn_format {
    background-color: #ffd5b8;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
    transition: .4s all;
}

.btn_format:hover {
    transform: scale(1.2);
    color: #26517c;
}

@media (min-width: 768px) and (max-width: 992px) {
    .nt-4 {
        font-size: 9px;
    }
}
</style>
