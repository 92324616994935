<template>
    <div class="mn_news">
        <NavBar />
        <div class="container mb-3">
            <div class="row justify-content-center">
                <h1>Kunjungan dan Diskusi Riset ISRG dengan Dinas Perpustakaan Daerah Sumatera Selatan</h1>
            </div>
        </div>
        <div class="container">
            <div class="row d-flex justify-content-between align-items-center w-100">
                <div class="col-auto">
                    <p class="tag mb-0">Diposting Senin, 26 Agustus 2024</p>
                </div>
                <div class="col-auto">
                    <p class="tag mb-0">Oleh Tim ISRG</p>
                </div>
            </div>
            <hr>
        </div>
        <div class="container my-0">
            <div class="row">
                <div class="jmb_img col-md-7 my-3">
                    <img src="@/assets/coverbrt2608.jpg" alt="logo" class="lg_img">
                </div>
                <div class="jmb_text col-md-5 my-3">
                    <p class="nt-4">
                        Kegiatan kunjungan sekaligus diskusi yang dilakukan oleh tim ISRG ke Dinas Perpustakaan Daerah
                        Sumatera Selatan menjadi salah satu bentuk kerja sama dan silaturahmi untuk mempererat
                        persaudaraan. Kegiatan ini diharapkan dapat menjadi pelestarian budaya termasuk budaya membaca
                        untuk generasi ke generasi sehingga dapat mengurangi isu minim literasi pada masyarakat di
                        Indonesia. Kunjungan ini dilakukan pada hari Senin, 26 Agustus 2024 yang berlokasi di Gedung
                        Dinas Perpustakaan Daerah Sumatera Selatan yang ada di kota Palembang. Saat berkunjung, tim ISRG
                        disambut baik oleh kepala dinas perpustakaan daerah Sumatera Selatan Bapak Muhamad Zaki Aslam,
                        S.IP., M.Si., Kepala Bidang Deposit, Pengembangan Koleksi dan Pengembangan Bahan Perpustakaan
                        Dra. Hj. Dewi Kencanawati, M.S, Humas perpustakaan daerah Indah Susanti, S.Sos, Bapak Samsudin,
                        S.S bidang deposit, Yusriadi Kamah, S.IP bidang pelestarian. Selain itu, diskusi yang dilakukan
                        cukup berjalan dengan baik di dalam ruangan yang ada di gedung Dinas Perpustakaan Daerah
                        Sumatera Selatan dan dihadiri oleh banyak orang-orang penting didalamnya.
                    </p>
                </div>
            </div>
        </div>
        <div class="container my-0">
            <div class="row justify-content-center">
                <div class="jmb_text">
                    <p class="nt-4">
                        Pada sesi diskusi, tim ISRG menjelaskan tentang kemajuan penelitian yang sedang dilaksanakan.
                        Pemaparan materi dan hasil penelitian yang sudah dilakukan sejauh ini dijelaskan melalui
                        proyektor yang disediakan di dalam ruangan. Materi yang disampaikan membahas tentang seputar
                        budaya aksara yang ada di daerah Sumatera Selatan seperti Aksara Ulu. Melalui diskusi yang telah
                        dilakukan, mitra dinas
                        perpustakaan menyatakan dukungan penuh terhadap kegiatan penelitian yang dilakukan oleh tim
                        ISRG. Beliau juga mendukung untuk mengusulkan aksara ulu dalam peraturan daerah Sumatera Selatan
                        dan juga mengusulkan aksara ulu Sumatera Selatan dalm code Unicode. Pada akhir sesi diskusi, tim
                        ISRG dan Mitra Dinas Perpustakaan melakukan sesi photo bersama. Dengan adanya kerja sama dan
                        diskusi yang dilakukan, diharapkan dapat mewujudkan pelestarian budaya aksara lokal seperti
                        budaya aksara di
                        daerah Sumatera Selatan yaitu Aksara Ulu.
                    </p>
                </div>
                <div class="col-md-4 my-3">
                    <div class="image_grid">
                        <img src="@/assets/brt2608bg1.jpg" class="nws_img02">
                    </div>
                </div>
                <div class="col-md-4 my-3">
                    <div class="image_grid">
                        <img src="@/assets/brt2608bg2.jpg" class="nws_img02">
                    </div>
                </div>
                <div class="col-md-4 my-3">
                    <div class="image_grid">
                        <img src="@/assets/brt2608bg3.jpg" class="nws_img02">
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <hr>
            <div class="row d-flex justify-content-between align-items-center w-100">
                <div class="col-auto">
                    <router-link class="btn btn_format mb-0" :to="{ name: 'NeWs05' }">Previous</router-link>
                </div>
                <div class="col-auto">
                    <router-link class="btn btn_format mb-0" :to="{ name: 'NeWs07' }">Next</router-link>
                </div>
            </div>
        </div>
        <FooTer />
    </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import FooTer from '@/components/FooTer.vue'
export default {
    name: 'NeWs06',
    components: {
        NavBar,
        FooTer,
    },
}
</script>

<style scoped>
.mn_news {
    margin-top: 140px;
}

.lg_img {
    width: 100%;
    aspect-ratio: 5.5/3;
}

.tag {
    font-size: 12px;
}

.jmb_text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.nt-4 {
    font-size: 13px;
    text-align: justify;
}

.nws_img01 {
    width: 80%;
    height: auto;
}

.nws_img02 {
    width: 100%;
    height: auto;
}

.btn_format {
    background-color: #ffd5b8;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
    transition: .4s all;
}

.btn_format:hover {
    transform: scale(1.2);
    color: #26517c;
}

@media (min-width: 768px) and (max-width: 992px) {
    .nt-4 {
        font-size: 9px;
    }
}
</style>
