<template>
    <div class="mn_news">
        <NavBar />
        <div class="container mb-3">
            <div class="row justify-content-center">
                <h1>Kunjungan Dinas Kebudayaan dan
                    Pariwisata Provinsi Sumatera Selatan ke ISRG Universitas Bina Darma</h1>
            </div>
        </div>
        <div class="container">
            <div class="row d-flex justify-content-between align-items-center w-100">
                <div class="col-auto">
                    <p class="tag mb-0">Diposting Rabu, 18 September 2024</p>
                </div>
                <div class="col-auto">
                    <p class="tag mb-0">Oleh Tim ISRG</p>
                </div>
            </div>
            <hr>
        </div>
        <div class="container my-0">
            <div class="row">
                <div class="jmb_img col-md-7 my-3">
                    <img src="@/assets/coverbrt1809part2.jpg" alt="logo" class="lg_img">
                </div>
                <div class="jmb_text col-md-5 my-3">
                    <p class="nt-4">
                        Kunjungan Kepala Bidang Dokumentasi dan Publikasi Budaya Dinas Kebudayaan dan Pariwisata
                        Provinsi Sumatera Selatan, Hj. Vinita Citra Karini, SE., M.Si., ke Intelligent Systems Research
                        Group (ISRG) Universitas Bina Darma pada 18 September 2024 menjadi momen penting dalam
                        memperkuat sinergi antara dunia riset dan pemerintah daerah. Kunjungan ini disambut langsung
                        oleh Ketua ISRG, Dr. Yesi Novaria Kunang, S.T., M.Kom., yang memaparkan progres riset ISRG
                        terkait Aksara Ulu Sumatera Selatan Multi Variant. Pembahasan fokus pada upaya pendokumentasian,
                        pelestarian, serta pengembangan aksara tradisional tersebut untuk memastikan keberlanjutannya di
                        era modern.
                    </p>
                </div>
            </div>
        </div>
        <div class="container my-0">
            <div class="row justify-content-center">
                <div class="jmb_text">
                    <p class="nt-4">
                        Diskusi juga menekankan pentingnya inovasi berbasis teknologi untuk memperluas pemahaman
                        masyarakat terhadap Aksara Ulu. Ide-ide seperti pengembangan perangkat lunak pengenalan aksara,
                        digitalisasi dokumen bersejarah, hingga pembuatan materi edukasi interaktif turut menjadi poin
                        pembicaraan utama. Dengan kolaborasi yang terjalin, diharapkan penelitian ini dapat memberikan
                        dampak signifikan bagi pelestarian warisan budaya lokal, serta memperkuat identitas budaya
                        Sumatera Selatan di tingkat nasional maupun internasional.
                    </p>
                </div>
                <div class="col-md-6 my-3">
                    <div class="image_grid">
                        <video class="nws_img01" controls>
                            <source src="@/assets/brt1809part2bg1.mp4" type="video/mp4">
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>
                <div class="col-md-6 my-3">
                    <div class="image_grid">
                        <video class="nws_img02" controls>
                            <source src="@/assets/brt1809part2bg2.mp4" type="video/mp4">
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <hr>
            <div class="row d-flex justify-content-between align-items-center w-100">
                <div class="col-auto">
                    <router-link class="btn btn_format mb-0" :to="{ name: 'NeWs10' }">Previous</router-link>
                </div>
                <div class="col-auto">
                    <router-link class="btn btn_format mb-0" :to="{ name: 'NeWs12' }">Next</router-link>
                </div>
            </div>
        </div>
        <FooTer />
    </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import FooTer from '@/components/FooTer.vue'
export default {
    name: 'NeWs11',
    components: {
        NavBar,
        FooTer,
    },
}
</script>

<style scoped>
.mn_news {
    margin-top: 140px;
}

.lg_img {
    width: 100%;
    aspect-ratio: 5.5/3;
}

.tag {
    font-size: 12px;
}

.jmb_text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.nt-4 {
    font-size: 13px;
    text-align: justify;
}

.nws_img01 {
    width: 56.5%;
    height: auto;
}

.nws_img02 {
    width: 100%;
    height: auto;
}

.btn_format {
    background-color: #ffd5b8;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
    transition: .4s all;
}

.btn_format:hover {
    transform: scale(1.2);
    color: #26517c;
}

@media (min-width: 768px) and (max-width: 992px) {
    .nt-4 {
        font-size: 9px;
    }
}
</style>
