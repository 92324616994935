<template>
    <div class="mn_news">
        <NavBar />
        <div class="container mb-3">
            <div class="row justify-content-center">
                <h1>Diskusi dengan penggiat aksara Ulu Prabumulih/Rambang, ibu Yasimas, S.Pd. 22 November
                    2024 bertempat di SDN 30 Prabumulih</h1>
            </div>
        </div>
        <div class="container">
            <div class="row d-flex justify-content-between align-items-center w-100">
                <div class="col-auto">
                    <p class="tag mb-0">Diposting Jumat, 22 November 2024</p>
                </div>
                <div class="col-auto">
                    <p class="tag mb-0">Oleh Tim ISRG</p>
                </div>
            </div>
            <hr>
        </div>
        <div class="container my-0">
            <div class="row">
                <div class="jmb_img col-md-7 my-3">
                    <img src="@/assets/coverbrt2211.png" alt="logo" class="lg_img">
                </div>
                <div class="jmb_text col-md-5 my-3">
                    <p class="nt-4">
                        Pada tanggal 22 November 2024, Intelligent Systems Research Group (ISRG) Universitas Bina Darma
                        mengadakan diskusi yang berlangsung hangat bersama Yasimas, S.Pd., seorang penggiat aksara Ulu
                        asal Prabumulih/Rambang. Acara ini bertempat di SDN 30 Prabumulih dengan narasumber langsung
                        dari bu Yasimas, S.Pd.
                    </p>
                </div>
            </div>
        </div>
        <div class="container my-0">
            <div class="row justify-content-center">
                <div class="jmb_text">
                    <p class="nt-4">
                        Diskusi ini berfokus pada upaya menggali potensi dan keberagaman aksara Ulu yang ada di wilayah
                        Rambang dan Prabumulih. Yasimas, yang juga seorang pendidik, membagikan pengalamannya dalam
                        memperkenalkan aksara Ulu kepada generasi muda melalui pendekatan pembelajaran di sekolah dasar.
                        Ia menekankan pentingnya mengenalkan aksara Ulu sejak dini agar nilai-nilai budaya lokal dapat
                        terjaga dan diwariskan secara berkesinambungan.
                    </p>
                </div>
                <div class="jmb_text">
                    <p class="nt-4">
                        Dalam pertemuan ini, tim ISRG memaparkan langkah-langkah pengembangan teknologi transliterasi
                        aksara Ulu yang telah mereka kembangkan, sekaligus meminta masukan dari Yasimas terkait
                        implementasi teknologi tersebut di Prabumulih dan sekitarnya. Kolaborasi antara ISRG dan
                        penggiat lokal seperti Yasimas diharapkan mampu menciptakan sinergi yang lebih kuat dalam
                        pelestarian aksara Ulu sebagai warisan budaya Sumatera Selatan.
                    </p>
                </div>
                <div class="col-md-6 my-3">
                    <div class="image_grid">
                        <video class="nws_img02" controls>
                            <source src="@/assets/brt2211bg1.mp4" type="video/mp4">
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <hr>
            <div class="row d-flex justify-content-between align-items-center w-100">
                <div class="col-auto">
                    <router-link class="btn btn_format mb-0" :to="{ name: 'NeWs13' }">Previous</router-link>
                </div>
                <div class="col-auto">
                    <router-link class="btn btn_format mb-0" :to="{ name: 'NeWs01' }">Next</router-link>
                </div>
            </div>
        </div>
        <FooTer />
    </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import FooTer from '@/components/FooTer.vue'
export default {
    name: 'NeWs14',
    components: {
        NavBar,
        FooTer,
    },
}
</script>

<style scoped>
.mn_news {
    margin-top: 140px;
}

.lg_img {
    width: 100%;
    aspect-ratio: 5.5/3;
}

.tag {
    font-size: 12px;
}

.jmb_text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.nt-4 {
    font-size: 13px;
    text-align: justify;
}

.nws_img01 {
    width: 100%;
    height: auto;
}

.nws_img02 {
    width: 65%;
    height: auto;
}

.btn_format {
    background-color: #ffd5b8;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
    transition: .4s all;
}

.btn_format:hover {
    transform: scale(1.2);
    color: #26517c;
}

@media (min-width: 768px) and (max-width: 992px) {
    .nt-4 {
        font-size: 9px;
    }
}
</style>
