<template>
    <div class="mn_news">
        <NavBar />
        <div class="container mb-3">
            <div class="row justify-content-center">
                <h1>Kunjungan ke Museum Subkoss Lubuk Linggau untuk Diskusi tentang Aksara Ulu Varian
                    Lubuk Linggau</h1>
            </div>
        </div>
        <div class="container">
            <div class="row d-flex justify-content-between align-items-center w-100">
                <div class="col-auto">
                    <p class="tag mb-0">Diposting Rabu, 11 September 2024</p>
                </div>
                <div class="col-auto">
                    <p class="tag mb-0">Oleh Tim ISRG</p>
                </div>
            </div>
            <hr>
        </div>
        <div class="container my-0">
            <div class="row">
                <div class="jmb_img col-md-7 my-3">
                    <img src="@/assets/kunjunganlinggau.jpg" alt="logo" class="lg_img">
                </div>
                <div class="jmb_text col-md-5 my-3">
                    <p class="nt-4">
                        Pada kegiatan kunjungan ke Museum Subkoss Lubuk Linggau, tim ISRG akan mendiskusikan aksara ulu
                        varian lubuk linggau dengan ahli yang ada di museum tersebut. Tim ISRG berangkat dari Kota
                        Palembang menuju Kota Lubuk Linggau tepatnya ke Museum Subkoss pada waktu pagi hari. Sesampainya
                        di Museum Subkoss, tim ISRG disambut oleh pihak museum dan narasumber yaitu Mas Berlian Susetyo.
                        Narasumber ini akan ikut berdiskusi dengan tim ISRG terkait persoalan aksara ulu varian lubuk
                        linggau. Dalam kegiatan kunjungan ini, tim ISRG berharap bisa mendapatkan informasi-informasi
                        terkait aksara ulu varian lubuk linggau sebaik mungkin. Diskusinya ini juga diharapkan dapat
                        menjadi ajang kerjasama antara tim ISRG dan pihak Museum Subkoss sehingga dapat bersama-sama
                        melestarikan budaya yang ada di daerah Sumatera Selatan.
                    </p>
                </div>
            </div>
        </div>
        <div class="container my-0">
            <div class="row justify-content-center">
                <div class="jmb_text">
                    <p class="nt-4">
                        Berbicara tentang Museum Subkoss, Museum ini menjadi salah satu museum yang menyimpan
                        nilai-nilai budaya tersendiri di Indonesia. Museum Sriwijaya Subkoss Garuda, yang terletak di
                        Jalan Garuda Hitam No. 1-2, Kelurahan Pasar Permiri, Kecamatan Lubuklinggau Barat II, Kota
                        Lubuklinggau, Sumatera Selatan, didirikan pada tahun 1988 di bangunan bekas rumah dinas Bupati
                        Kabupaten Musi Rawas untuk mengenang perjuangan rakyat Sumatera Selatan dalam revolusi fisik
                        mempertahankan kemerdekaan Indonesia pada tahun 1945-1949. Dengan arsitektur tradisional dan
                        luas bangunan 1.700 m², museum ini memiliki sekitar 184 koleksi bersejarah, termasuk Lokomotif
                        Uap C3082 dan Mobil Jeep Willys ‘Tarzan’, peninggalan pahlawan nasional dr. AK. Gani, serta
                        berbagai senjata seperti meriam kecepek, tombak, keris, dan pedang. Selain itu, museum ini juga
                        menyimpan arsip foto, dokumen, dan koleksi peninggalan budaya seperti aksara ulu varian Lubuk
                        Linggau, yang menjadi bagian penting dari warisan lokal. Sebagai tempat pelestarian nilai
                        perjuangan dan budaya, museum ini memainkan peran penting dalam mengingat dan menjaga sejarah
                        rakyat Sumatera Selatan.
                    </p>
                </div>
                <div class="col-md-4 my-3">
                    <div class="image_grid">
                        <img src="@/assets/depanmuseumsubkoss.jpg" class="nws_img02">
                    </div>
                </div>
                <div class="col-md-4 my-3">
                    <div class="image_grid">
                        <img src="@/assets/halamanmuseumsubkoss.png" class="nws_img02">
                    </div>
                </div>
                <div class="col-md-4 my-3">
                    <div class="image_grid">
                        <img src="@/assets/dalammuseumsubkoss.png" class="nws_img02">
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <hr>
            <div class="row d-flex justify-content-between align-items-center w-100">
                <div class="col-auto">
                    <router-link class="btn btn_format mb-0" :to="{ name: 'NeWs08' }">Previous</router-link>
                </div>
                <div class="col-auto">
                    <router-link class="btn btn_format mb-0" :to="{ name: 'NeWs10' }">Next</router-link>
                </div>
            </div>
        </div>
        <FooTer />
    </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import FooTer from '@/components/FooTer.vue'
export default {
    name: 'NeWs09',
    components: {
        NavBar,
        FooTer,
    },
}
</script>

<style scoped>
.mn_news {
    margin-top: 140px;
}

.lg_img {
    width: 100%;
    aspect-ratio: 5.5/3;
}

.tag {
    font-size: 12px;
}

.jmb_text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.nt-4 {
    font-size: 13px;
    text-align: justify;
}

.nws_img01 {
    width: 80%;
    height: auto;
}

.nws_img02 {
    width: 100%;
    height: auto;
}

.btn_format {
    background-color: #ffd5b8;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
    transition: .4s all;
}

.btn_format:hover {
    transform: scale(1.2);
    color: #26517c;
}

@media (min-width: 768px) and (max-width: 992px) {
    .nt-4 {
        font-size: 9px;
    }
}
</style>
