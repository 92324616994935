<template>
    <div class="mn_news">
        <NavBar />
        <div class="container mb-3">
            <div class="row justify-content-center">
                <h1>Audiensi Ketua ISRG Universitas Bina Darma
                    dengan PLT. Kepala BBPSS di UPT Balai Bahasa
                    Provinsi Sumatera Selatan</h1>
            </div>
        </div>
        <div class="container">
            <div class="row d-flex justify-content-between align-items-center w-100">
                <div class="col-auto">
                    <p class="tag mb-0">Diposting Rabu, 18 September 2024</p>
                </div>
                <div class="col-auto">
                    <p class="tag mb-0">Oleh Tim ISRG</p>
                </div>
            </div>
            <hr>
        </div>
        <div class="container my-0">
            <div class="row">
                <div class="jmb_img col-md-7 my-3">
                    <img src="@/assets/coverbrt1809.jpg" alt="logo" class="lg_img">
                </div>
                <div class="jmb_text col-md-5 my-3">
                    <p class="nt-4">
                        Audiensi Ketua Riset Intelligent Systems Research Group (ISRG) Universitas Bina Darma, Dr. Yesi
                        Novaria Kunang, S.T., M.Kom., dengan PLT. Kepala Balai Bahasa Provinsi Sumatera Selatan (BBPSS),
                        Nukman, S.S., M.Hum., di UPT Balai Bahasa Provinsi Sumatera Selatan pada 18 September 2024,
                        berlangsung produktif. Pertemuan ini membahas berbagai langkah strategis untuk melestarikan
                        Aksara Ulu sebagai warisan budaya lokal Sumatera Selatan. Diskusi berfokus pada kolaborasi
                        penelitian, pengumpulan data historis, dan upaya digitalisasi Aksara Ulu agar lebih relevan
                        dengan perkembangan teknologi modern. PLT. Kepala BBPSS menyambut baik inisiatif ini dan
                        menawarkan dukungan dalam penyediaan data dokumentasi yang dimiliki oleh Balai Bahasa.
                    </p>
                </div>
            </div>
        </div>
        <div class="container my-0">
            <div class="row justify-content-center">
                <div class="jmb_text">
                    <p class="nt-4">
                        Audiensi ini menjadi momen penting untuk membangun sinergi antara dunia riset dan lembaga
                        pelestarian budaya. Dengan kolaborasi yang terjalin, diharapkan upaya pelestarian Aksara Ulu
                        tidak hanya berfokus pada dokumentasi, tetapi juga pada pengembangan teknologi berbasis budaya
                        yang mampu menjawab tantangan globalisasi tanpa melupakan akar tradisionalnya.
                    </p>
                </div>
                <div class="col-md-6 my-3">
                    <div class="image_grid">
                        <img src="@/assets/brt1809bg1.jpg " class="nws_img01">
                    </div>
                </div>
                <div class="col-md-6 my-3">
                    <div class="image_grid">
                        <video class="nws_img02" controls>
                            <source src="@/assets/brt1809bg2.mp4" type="video/mp4">
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <hr>
            <div class="row d-flex justify-content-between align-items-center w-100">
                <div class="col-auto">
                    <router-link class="btn btn_format mb-0" :to="{ name: 'NeWs09' }">Previous</router-link>
                </div>
                <div class="col-auto">
                    <router-link class="btn btn_format mb-0" :to="{ name: 'NeWs11' }">Next</router-link>
                </div>
            </div>
        </div>
        <FooTer />
    </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import FooTer from '@/components/FooTer.vue'
export default {
    name: 'NeWs10',
    components: {
        NavBar,
        FooTer,
    },
}
</script>

<style scoped>
.mn_news {
    margin-top: 140px;
}

.lg_img {
    width: 100%;
    aspect-ratio: 5.5/3;
}

.tag {
    font-size: 12px;
}

.jmb_text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.nt-4 {
    font-size: 13px;
    text-align: justify;
}

.nws_img01 {
    width: 80%;
    height: auto;
}

.nws_img02 {
    width: 45%;
    height: auto;
}

.btn_format {
    background-color: #ffd5b8;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
    transition: .4s all;
}

.btn_format:hover {
    transform: scale(1.2);
    color: #26517c;
}

@media (min-width: 768px) and (max-width: 992px) {
    .nt-4 {
        font-size: 9px;
    }
}
</style>
