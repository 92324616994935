<template>
    <div class="mn_public">
        <NavBar />
        <div class="container">
            <div class="row justify-content-center">
                <h1>Publication</h1>
                <hr class="line_down">
            </div>
        </div>
        <div class="container my-5">
            <div class="row">
                <div class="d-flex justify-content-between align-items-center my-3">
                    <div class="jmb_img">
                        <img src="@/assets/symbol.png" alt="logo" class="lg_img">
                    </div>
                    <div class="jmb_text">
                        <p class="nt-4">
                            A. A. Razio, Y. N. Kunang, I. Z. Yadi and S. D. Purnamasari, "Implementation of Neural
                            Machine Transliteration from Komering Language to Indonesian Language Using Recurrent Neural
                            Network (RNN) Model," 2024 International Conference on Electrical Engineering and Computer
                            Science (ICECOS), Palembang, Indonesia, 2024, pp. 141-146, doi:
                            10.1109/ICECOS63900.2024.10791254.
                        </p>
                    </div>
                </div>
                <div class="d-flex justify-content-between align-items-center my-3">
                    <div class="jmb_img">
                        <img src="@/assets/symbol.png" alt="logo" class="lg_img">
                    </div>
                    <div class="jmb_text">
                        <p class="nt-4">
                            Hamanrora, M. D., Kunang, Y. N., Yadi, I. Z., & Mahmud, M. (2024). Image segmentation of
                            Komering script using bounding box. Indonesian Journal of Electrical Engineering and
                            Computer Science, 35(3), 1565. <a
                                href="https://doi.org/10.11591/ijeecs.v35.i3.pp1565-1578">https:// doi.org/ 10.11591/
                                ijeecs. v35. i3. pp1565-1578</a>
                        </p>
                    </div>
                </div>
                <div class="d-flex justify-content-between align-items-center my-3">
                    <div class="jmb_img">
                        <img src="@/assets/symbol.png" alt="logo" class="lg_img">
                    </div>
                    <div class="jmb_text">
                        <p class="nt-4">
                            Kunang, Yesi Novaria, Siti Nurmaini, Deris Stiawan, and Bhakti Yudho Suprapto. "An
                            end-to-end intrusion detection system with IoT dataset using deep learning with unsupervised
                            feature extraction." International Journal of Information Security (2024): 1-30.
                        </p>
                    </div>
                </div>
                <div class="d-flex justify-content-between align-items-center my-3">
                    <div class="jmb_img">
                        <img src="@/assets/symbol.png" alt="logo" class="lg_img">
                    </div>
                    <div class="jmb_text">
                        <p class="nt-4">
                            Sapitri, Wulan, Yesi Novaria Kunang, Ilman Zuhri Yadi, and Mahmud Mahmud. "The Impact of
                            Data Augmentation Techniques on the Recognition of Script Images in Deep Learning Models."
                            Jurnal Online Informatika 8, no. 2 (2023): 169-176.
                        </p>
                    </div>
                </div>
                <div class="d-flex justify-content-between align-items-center my-3">
                    <div class="jmb_img">
                        <img src="@/assets/symbol.png" alt="logo" class="lg_img">
                    </div>
                    <div class="jmb_text">
                        <p class="nt-4">
                            Atmojo, Toni Tri, and Yesi Novaria Kunang. "Machine Learning-Based E-Archive for Archives
                            Management of South Sumatra Province." Journal of Information Systems and Informatics 5, no.
                            4 (2023): 1491-1507.
                        </p>
                    </div>
                </div>
                <div class="d-flex justify-content-between align-items-center my-3">
                    <div class="jmb_img">
                        <img src="@/assets/symbol.png" alt="logo" class="lg_img">
                    </div>
                    <div class="jmb_text">
                        <p class="nt-4">
                            Fadilah, M. Rizki, Ilman Zuhri Yadi, Yesi Novaria Kunang, and Susan Dian Purnamasari.
                            "Machine Learning-Based Komering Language Translation Engine with Bidirectional RNN Model
                            Algorithm." In 2023 International Conference on Information Technology and Computing
                            (ICITCOM), pp. 62-66. IEEE, 2023.
                        </p>
                    </div>
                </div>
                <div class="d-flex justify-content-between align-items-center my-3">
                    <div class="jmb_img">
                        <img src="@/assets/symbol.png" alt="logo" class="lg_img">
                    </div>
                    <div class="jmb_text">
                        <p class="nt-4">
                            Subhan, Ahmad, Yesi Novaria Kunang, and Ilman Zuhri Yadi. "Analyzing the Attack Pattern of
                            Brute Force Attack on SSH Port." In 2023 International Conference on Information Technology
                            and Computing (ICITCOM), pp. 67-72. IEEE, 2023.
                        </p>
                    </div>
                </div>
                <div class="d-flex justify-content-between align-items-center my-3">
                    <div class="jmb_img">
                        <img src="@/assets/symbol.png" alt="logo" class="lg_img">
                    </div>
                    <div class="jmb_text">
                        <p class="nt-4">
                            Wulandari, Nia Taresta, Yesi Novaria Kunang, and Ilman Zuhri Yadi. "Indonesian Dewey Decimal
                            Classification System Using Support Vector Machine and Neural Network Algorithms." In 2023
                            IEEE 7th International Conference on Information Technology, Information Systems and
                            Electrical Engineering (ICITISEE), pp. 168-173. IEEE, 2023.
                        </p>
                    </div>
                </div>
                <div class="d-flex justify-content-between align-items-center my-3">
                    <div class="jmb_img">
                        <img src="@/assets/symbol.png" alt="logo" class="lg_img">
                    </div>
                    <div class="jmb_text">
                        <p class="nt-4">
                            Henuarianto, Irfan, Ilman Zuhri Yadi, Yesi Novaria Kunang, and Susan Dian Purnamasari.
                            "Komering-Indonesian Machine Translation Using Embedding RNN." In 2023 IEEE 7th
                            International Conference on Information Technology, Information Systems and Electrical
                            Engineering (ICITISEE), pp. 163-167. IEEE, 2023.
                        </p>
                    </div>
                </div>
                <div class="d-flex justify-content-between align-items-center my-3">
                    <div class="jmb_img">
                        <img src="@/assets/symbol.png" alt="logo" class="lg_img">
                    </div>
                    <div class="jmb_text">
                        <p class="nt-4">
                            Kunang, Yesi Novaria, and Widya Putri Mentari. "Analysis of the Impact of Vectorization
                            Methods on Machine Learning-Based Sentiment Analysis of Tweets Regarding Readiness for
                            Offline Learning." JUITA: Jurnal Informatika 11, no. 2 (2023): 271-280.
                        </p>
                    </div>
                </div>
                <div class="d-flex justify-content-between align-items-center my-3">
                    <div class="jmb_img">
                        <img src="@/assets/symbol.png" alt="logo" class="lg_img">
                    </div>
                    <div class="jmb_text">
                        <p class="nt-4">
                            Sari, Tia Permata, and Yesi Novaria Kunang. "Pengembangan Aplikasi Transliterasi Teks Latin
                            ke Aksara Ulu (Komering) Berbasis Web." Jurnal PROCESSOR 18, no. 2 (2023).
                        </p>
                    </div>
                </div>
                <div class="d-flex justify-content-between align-items-center my-3">
                    <div class="jmb_img">
                        <img src="@/assets/symbol.png" alt="logo" class="lg_img">
                    </div>
                    <div class="jmb_text">
                        <p class="nt-4">
                            Kunang, Yesi Novaria, Ilman Zuhri Yadi, and Mansyur Husin. "A New Deep Learning-Based Mobile
                            Application for Komering Character Recognition." In 2022 5th International Seminar on
                            Research of Information Technology and Intelligent Systems (ISRITI), pp. 294-299. IEEE,
                            2022.
                        </p>
                    </div>
                </div>
                <div class="d-flex justify-content-between align-items-center my-3">
                    <div class="jmb_img">
                        <img src="@/assets/symbol.png" alt="logo" class="lg_img">
                    </div>
                    <div class="jmb_text">
                        <p class="nt-4">
                            Kunang, Yesi Novaria, Siti Nurmaini, Deris Stiawan, and Bhakti Yudho Suprapto. "Deep
                            learning with focal loss approach for attacks classification." Telkomnika (Telecommunication
                            Computing Electronics and Control) 19, no. 4 (2021): 1407-1418.
                        </p>
                    </div>
                </div>
                <div class="d-flex justify-content-between align-items-center my-3">
                    <div class="jmb_img">
                        <img src="@/assets/symbol.png" alt="logo" class="lg_img">
                    </div>
                    <div class="jmb_text">
                        <p class="nt-4">
                            Prasetya, Adam, Ferdiansyah Ferdiansyah, Yesi Novaria Kunang, Edi Surya Negara, and Winoto
                            Chandra. "Sentiment Analisis Terhadap Cryptocurrency Berdasarkan Comment Dan Reply Pada
                            Platform Twitter." Journal of Information Systems and Informatics 3, no. 2 (2021): 268-277.
                        </p>
                    </div>
                </div>
                <div class="d-flex justify-content-between align-items-center my-3">
                    <div class="jmb_img">
                        <img src="@/assets/symbol.png" alt="logo" class="lg_img">
                    </div>
                    <div class="jmb_text">
                        <p class="nt-4">
                            Kunang, Yesi Novaria, Siti Nurmaini, Deris Stiawan, and Bhakti Yudho Suprapto. "Improving
                            classification attacks in IoT intrusion detection system using Bayesian hyperparameter
                            optimization." In 2020 3rd International Seminar on Research of Information Technology and
                            Intelligent Systems (ISRITI), pp. 146-151. IEEE, 2020.
                        </p>
                    </div>
                </div>
                <div class="d-flex justify-content-between align-items-center my-3">
                    <div class="jmb_img">
                        <img src="@/assets/symbol.png" alt="logo" class="lg_img">
                    </div>
                    <div class="jmb_text">
                        <p class="nt-4">
                            Mahmud, Mahmud, and Yesi Novaria Kunang. "Pengembangan Aplikasi Pengenalan Aksara Komering
                            Menggunakan Metode Deep Learning Berbasis Android." Journal of Computer and Information
                            Systems Ampera 1, no. 2 (2020): 99-111.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <FooTer />
    </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue';
import FooTer from '@/components/FooTer.vue';
export default {
    name: 'PubliCation',
    components: {
        NavBar,
        FooTer,
    },
}
</script>
<style scoped>
.mn_public {
    margin-top: 140px;
}

.line_down {
    width: 35%;
}

.lg_img {
    width: 70px;
    height: 70px;
    aspect-ratio: 1/1;
}

.jmb_text {
    text-align: justify;
}

.nt-4 {
    margin: 0;
    line-height: 1.5;
}

.d-flex>.jmb_text {
    flex-grow: 1;
    padding-left: 25px;
}
</style>