import { createRouter, createWebHistory } from 'vue-router';
import HoMe from '@/pages/HoMe.vue';
import ProFile from '@/pages/ProFile.vue';
import VandM from '@/pages/VandM.vue';
import ResearchActivities from '@/pages/ResearchActivities.vue';
import PubliCation from '@/pages/PubliCation.vue';
import HaKi from '@/pages/HaKi.vue';
import DataSet from '@/pages/DataSet.vue';
import ResearchersProfile from '@/pages/ResearchersProfile.vue';
import ResearchStudent from '@/pages/ResearchStudent.vue';
import PartNer from '@/pages/PartNer.vue';
import DocumenTation from '@/pages/DocumenTation.vue';
import NeWs01 from '@/news/NeWs01.vue';
import NeWs02 from '@/news/NeWs02.vue';
import NeWs03 from '@/news/NeWs03.vue';
import NeWs04 from '@/news/NeWs04.vue';
import NeWs05 from '@/news/NeWs05.vue';
import NeWs06 from '@/news/NeWs06.vue';
import NeWs07 from '@/news/NeWs07.vue';
import NeWs08 from '@/news/NeWs08.vue';
import NeWs09 from '@/news/NeWs09.vue';
import NeWs10 from '@/news/NeWs10.vue';
import NeWs11 from '@/news/NeWs11.vue';
import NeWs12 from '@/news/NeWs12.vue';
import NeWs13 from '@/news/NeWs13.vue';
import NeWs14 from '@/news/NeWs14.vue';

const routes = [
    { path: '/', name: 'HoMe', component: HoMe, meta: { title: 'Intelligent Systems Research Group' } },
    { path: '/ProFile', name: 'ProFile', component: ProFile, meta: { title: 'Profile' } },
    { path: '/VandM', name: 'VandM', component: VandM, meta: { title: 'Vision and Mission' } },
    { path: '/ResearchActivities', name: 'ResearchActivities', component: ResearchActivities, meta: { title: 'Research Activities' } },
    { path: '/PubliCation', name: 'PubliCation', component: PubliCation, meta: { title: 'Publication' } },
    { path: '/HaKi', name: 'HaKi', component: HaKi, meta: { title: 'Haki' } },
    { path: '/DataSet', name: 'DataSet', component: DataSet, meta: { title: 'Dataset' } },
    { path: '/ResearchersProfile', name: 'ResearchersProfile', component: ResearchersProfile, meta: { title: 'Researchers Profile' } },
    { path: '/ResearchStudent', name: 'ResearchStudent', component: ResearchStudent, meta: { title: 'Research Student' } },
    { path: '/PartNer', name: 'PartNer', component: PartNer, meta: { title: 'Partner' } },
    { path: '/DocumenTation', name: 'DocumenTation', component: DocumenTation, meta: { title: 'Documentation' } },
    { path: '/NeWs01', name: 'NeWs01', component: NeWs01, meta: { title: 'Aplikasi Aksara Ulu (Kanganga) di Platform Android' } },
    { path: '/NeWs02', name: 'NeWs02', component: NeWs02, meta: { title: 'Aplikasi Alih Aksara Dokumen Aksara Ulu Berbasis Website' } },
    { path: '/NeWs03', name: 'NeWs03', component: NeWs03, meta: { title: 'Aplikasi Transliterasi Aksara Latin ke Aksara Ulu Berbasis Website' } },
    { path: '/NeWs04', name: 'NeWs04', component: NeWs04, meta: { title: 'Workshop Forum Discussion Group (FGD) Pengenalan dan Penggunaan Aplikasi Aksara Ulu Oleh Intelligence System Research Group (ISRG) Universitas Bina Darma' } },
    { path: '/NeWs05', name: 'NeWs05', component: NeWs05, meta: { title: 'FGD Kearifan Lokal "Pelestarian Naskah Kuno Aksara Ulu Sumatera Selatan" dan Launching Aplikasi AKAS' } },
    { path: '/NeWs06', name: 'NeWs06', component: NeWs06, meta: { title: 'Kunjungan dan Diskusi Riset ISRG dengan Dinas Perpustakaan Daerah Sumatera Selatan' } },
    { path: '/NeWs07', name: 'NeWs07', component: NeWs07, meta: { title: 'Diskusi Pengembangan Multivarian Aksara Ulu Sumatera Selatan Bersama Dinas Kebudayaan dan Pariwisata Sumatera Selatan' } },
    { path: '/NeWs08', name: 'NeWs08', component: NeWs08, meta: { title: 'Diskusi Bersama Ariq Syauqi, MA. dan Komunitas Aksara Ulu Sumsel Mengenai Penelitian Aksara di Selatan Sumatera' } },
    { path: '/NeWs09', name: 'NeWs09', component: NeWs09, meta: { title: 'Kunjungan ke Museum Subkoss Lubuk Linggau untuk Diskusi tentang Aksara Ulu Varian Lubuk Linggau' } },
    { path: '/NeWs10', name: 'NeWs10', component: NeWs10, meta: { title: 'Audiensi Ketua ISRG Universitas Bina Darma dengan PLT. Kepala BBPSS di UPT Balai Bahasa Provinsi Sumatera Selatan' } },
    { path: '/NeWs11', name: 'NeWs11', component: NeWs11, meta: { title: 'Kunjungan Dinas Kebudayaan dan Pariwisata Provinsi Sumatera Selatan ke ISRG Universitas Bina Darma' } },
    { path: '/NeWs12', name: 'NeWs12', component: NeWs12, meta: { title: 'Diskusi Kelanjutan Pengembangan Aplikasi Aksara Ulu Sumatera Selatan bersama Dinas Kebudayaan dan Pariwisata' } },
    { path: '/NeWs13', name: 'NeWs13', component: NeWs13, meta: { title: 'Forum Kelompok Diskusi dan Pengenalan Aplikasi Cerdas Transliterasi Multi Varian Aksara Ulu Sumatera Selatan' } },
    { path: '/NeWs14', name: 'NeWs14', component: NeWs14, meta: { title: 'Diskusi dengan penggiat aksara Ulu Prabumulih/Rambang, ibu Yasimas, S.Pd. 22 November 2024 bertempat di SDN 30 Prabumulih' } }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        // Memastikan body overflow diatur dengan benar setelah setiap navigasi
        document.body.style.overflow = 'auto';
        document.documentElement.style.overflow = 'auto';

        // Logika scroll yang ada
        if (savedPosition) {
            return savedPosition;
        } else {
            return { top: 0 };
        }
    }
});

export default router;