<template>
    <div class="mn_news">
        <NavBar />
        <div class="container mb-3">
            <div class="row justify-content-center">
                <h1>Diskusi Bersama Ariq Syauqi, MA. dan Komunitas Aksara Ulu Sumsel Mengenai Penelitian
                    Aksara di Selatan Sumatera</h1>
            </div>
        </div>
        <div class="container">
            <div class="row d-flex justify-content-between align-items-center w-100">
                <div class="col-auto">
                    <p class="tag mb-0">Diposting Senin, 9 September 2024</p>
                </div>
                <div class="col-auto">
                    <p class="tag mb-0">Oleh Tim ISRG</p>
                </div>
            </div>
            <hr>
        </div>
        <div class="container my-0">
            <div class="row">
                <div class="jmb_img col-md-7 my-3">
                    <img src="@/assets/coverbrt0909.jpg" alt="logo" class="lg_img">
                </div>
                <div class="jmb_text col-md-5 my-3">
                    <p class="nt-4">
                        Acara audiensi bersama Ariq Syauqi, M.A., dari Universitas Atelier National de RecHerche
                        Typographic Nancy (ANRT), yang didampingi Ketua Komunitas Pecinta Aksara Ulu Sumatera Selatan,
                        Nuzulur Ramadhona, M.Hum., berlangsung dengan lancar di Ruang Multimedia, Perpustakaan
                        Universitas Bina Darma, Palembang, pada 9 September 2024. Diskusi ini berfokus pada penelitian
                        aksara tradisional di wilayah Selatan Sumatera. Kehadiran alumni ANRT yang memiliki pengalaman
                        mendalam dalam bidang tipografi dan penulisan, serta perwakilan komunitas pelestari budaya
                        lokal, memberikan perspektif berharga bagi tim ISRG dalam memperkaya penelitian mereka.
                    </p>
                </div>
            </div>
        </div>
        <div class="container my-0">
            <div class="row justify-content-center">
                <div class="jmb_text">
                    <p class="nt-4">
                        Universitas Atelier National de RecHerche Typographic Nancy (ANRT) di Perancis dikenal sebagai
                        institusi terkemuka di dunia dalam bidang tipografi dan desain tulisan. ANRT aktif menghasilkan
                        karya-karya inovatif, termasuk eksplorasi aksara tradisional yang belum mendapatkan pengakuan
                        standar internasional. Salah satu alumninya, Ariq Syauqi, M.A., adalah seorang peneliti
                        pasca-master yang mendalami studi representasi tipografis aksara tradisional Sumatera Selatan,
                        seperti Lampung, Ulu, dan Kerinci. Dalam penelitiannya, ia bekerja sama dengan lembaga
                        internasional seperti SEI UC Berkeley dan IDG Hochschule Mainz. Ariq berfokus pada pengembangan
                        aksara-aksara ini, yang hingga kini belum masuk dalam sistem Unicode. Dengan latar belakang
                        pendidikan internasional yang kuat, Ariq berperan penting dalam membawa Aksara Ulu ke panggung
                        global melalui pendekatan modern dan berbasis teknologi.
                    </p>
                </div>
                <div class="col-md-4 my-3">
                    <div class="image_grid">
                        <img src="@/assets/anrtnbg1.jpg" class="nws_img02">
                    </div>
                </div>
                <div class="col-md-4 my-3">
                    <div class="image_grid">
                        <img src="@/assets/anrtnbg2.jpg" class="nws_img02">
                    </div>
                </div>
                <div class="col-md-4 my-3">
                    <div class="image_grid">
                        <img src="@/assets/anrtnbg3.jpg" class="nws_img02">
                    </div>
                </div>
                <div class="jmb_text">
                    <p class="nt-4">
                        Perkumpulan Pecinta Aksara Ulu adalah komunitas yang didirikan oleh generasi muda Sumatera
                        Selatan untuk melestarikan budaya tulis khas daerah, yakni Surat Ulu atau Aksara Ulu. Komunitas
                        ini menjadi wadah bagi masyarakat untuk melindungi, mengembangkan, dan memanfaatkan warisan
                        budaya tulis tersebut. Di bawah kepemimpinan Nuzulur Ramadhona, M.Hum., atau yang dikenal
                        sebagai Dhona Ulu, komunitas ini terus aktif dalam kegiatan penelitian, pelatihan, dan edukasi.
                        Sebagai seorang penggiat, pelestari, dan peneliti Aksara Ulu, Nuzulur Ramadhona memiliki peran
                        besar dalam menghidupkan kembali aksara ini di tengah masyarakat modern. Kerja sama antara ANRT,
                        ISRG, dan komunitas ini diharapkan dapat membawa dampak positif yang signifikan dalam
                        pelestarian budaya tulis Sumatera Selatan di tingkat nasional maupun internasional.
                    </p>
                </div>
                <div class="jmb_text">
                    <p class="nt-4">
                        Gambar sistem penulisan dunia yang ditampilkan menggambarkan evolusi dan keberagaman aksara yang
                        tersebar di berbagai belahan dunia, mencerminkan kekayaan budaya dan perkembangan peradaban
                        manusia. Dalam konteks ini, aksara tradisional Sumatera Selatan, seperti Surat Ulu atau Aksara
                        Ulu, merupakan bagian unik dari warisan budaya global yang sayangnya masih belum diakui dalam
                        standar internasional seperti Unicode. Hal ini menjadi salah satu fokus diskusi dalam audiensi,
                        di mana tim ISRG bersama Ariq Syauqi, M.A., dan komunitas Pecinta Aksara Ulu membahas upaya
                        pelestarian dan pengembangan aksara-aksara tradisional Sumatera Selatan, seperti Ulu, Lampung,
                        dan Kerinci, agar dapat diintegrasikan ke dalam teknologi digital modern. Penelitian ini tidak
                        hanya penting untuk melestarikan budaya lokal, tetapi juga untuk menempatkan aksara tradisional
                        ini dalam peta sistem penulisan dunia, memastikan keberadaannya tetap relevan di tengah
                        perkembangan globalisasi dan teknologi.
                    </p>
                </div>
                <div class="col-md-4 my-3">
                    <div class="image_grid">
                        <img src="@/assets/brt0909bg1.jpg" class="nws_img02">
                    </div>
                </div>
                <div class="col-md-4 my-3">
                    <div class="image_grid">
                        <img src="@/assets/brt0909bg2.jpg" class="nws_img02">
                    </div>
                </div>
                <div class="col-md-4 my-3">
                    <div class="image_grid">
                        <img src="@/assets/brt0909bg3.jpg" class="nws_img02">
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <hr>
            <div class="row d-flex justify-content-between align-items-center w-100">
                <div class="col-auto">
                    <router-link class="btn btn_format mb-0" :to="{ name: 'NeWs07' }">Previous</router-link>
                </div>
                <div class="col-auto">
                    <router-link class="btn btn_format mb-0" :to="{ name: 'NeWs09' }">Next</router-link>
                </div>
            </div>
        </div>
        <FooTer />
    </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import FooTer from '@/components/FooTer.vue'
export default {
    name: 'NeWs08',
    components: {
        NavBar,
        FooTer,
    },
}
</script>

<style scoped>
.mn_news {
    margin-top: 140px;
}

.lg_img {
    width: 100%;
    aspect-ratio: 5.5/3;
}

.tag {
    font-size: 12px;
}

.jmb_text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.nt-4 {
    font-size: 13px;
    text-align: justify;
}

.nws_img01 {
    width: 80%;
    height: auto;
}

.nws_img02 {
    width: 100%;
    height: auto;
}

.btn_format {
    background-color: #ffd5b8;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
    transition: .4s all;
}

.btn_format:hover {
    transform: scale(1.2);
    color: #26517c;
}

@media (min-width: 768px) and (max-width: 992px) {
    .nt-4 {
        font-size: 9px;
    }
}
</style>
